<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="date" scrollable @input="modal = false; $refs.dialog.save(date); search();">
        </v-date-picker>
      </v-dialog>
      <v-toolbar-title>
        <span>{{ date }}</span>
        <span class="ml-5">Активных : {{ active }}</span>
        <span class="ml-5">Отключенных : {{ finished }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-row dense class="mt-4 px-2 hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Изображение</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-start">
        <span class="text-caption pl-10">Название</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Телефон</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-2">Скрыт</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption pr-11">Кол-во заказов</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Логин</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Не заходил</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-start">
        <span class="text-caption pl-4">Дата рег.</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-start">
        <span class="text-caption pl-4">Дата акт.</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : ''" no-gutters v-for="(store, i) in list" :key="store.id" class="my-1 grey lighten-4">
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Изображение:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
        <v-avatar v-if="store.logo" size="48px">
          <img :src="store.logo" alt="Store Logo">
        </v-avatar>
        <v-avatar v-else size="48px">
          <img src="/user_avatar.png" alt="">
        </v-avatar>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Название:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-start justify-md-start justify-sm-start align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ i + 1 }} : {{ store.name }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Тел.:</span>
      </v-col>
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-col cols="8" v-bind="attrs" v-on="on" class="col col-lg-2 col-md-2 col-sm-2 d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
            <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ store.phone | normalizePhone | minimizePhone }}</span>
          </v-col>
        </template>
        <span>{{ store.phone | normalizePhone }}</span>
      </v-tooltip>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Скрыт:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex align-center justify-end justify-lg-center justify-md-center justify-sm-center">
        <v-checkbox v-model="store.hidden"
                    readonly
                    hide-details
                    class="ma-0"
                    :class="$vuetify.breakpoint.xs ? '' : 'pa-0'"/>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Кол-во заказов:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-start justify-md-start justify-sm-start align-center">
        <span class="caption">Всего:</span>
        <span class="text-body-2 font-weight-bold">{{ store.total_count }}</span>
        <span class="px-1 font-weight-bold">/</span>
        <span class="caption">Сегодня:</span>
        <span class="text-body-2 font-weight-bold">{{ store.count }}</span>
      </v-col>
      <v-col cols="12" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters d-flex align-center">
        <v-row v-for="user in store.users" :key="user.id">
          <v-col cols="5" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-center justify-lg-start justify-md-start justify-sm-start d-flex align-center">
            <span class="text-body-2">{{ user.login }}</span>
          </v-col>
          <v-col cols="1" class="row no-gutters d-flex align-center">
            <v-checkbox :model="user.is_first_login"
                        hide-details
                        readonly
                        class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="6" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-space-around">
            <span class="text-body-2">{{ user.created_at | timeFormat }}</span>
            <span class="text-body-2">{{ user.updated_at | timeFormat }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import axios from "@/services/axios";
import format from "date-fns/format";

export default {
  data: () => ({
    list: [],
    hidden: true,
    modal: false,
    date: new Date().toISOString().substr(0, 10),
  }),

  created() {
    this.search();
  },

  computed: {
    active() {
      return this.list.filter(store => !store.hidden).length;
    },
    finished() {
      return this.list.filter(store => store.hidden).length;
    }
  },

  methods: {
    search() {
      axios.send({
        url: "/status/stores?date=" + this.date,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      })
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    },
    minimizePhone: (p) => {
      if (!p) {
        return ""
      }
      if (p.length > 17) {
        return p.substr(0, 17);
      }
      return p
    }
  }
}
</script>

<style scoped>
.active {
  border-left: 8px solid #3fc44f;
}

.order_store {
  border-left: 8px solid #409ecd;
}

.order_client {
  border-left: 8px solid #3cd1c2;
}

.finished {
  border-left: 8px solid #878787;
}
</style>
